<template>
    <main>
        <div class="main">
            <h1 class="title">テクノクラフ ログインページ</h1>
            <img src="@/image/logo.png" alt="">
            <div class="mt-2 text-center">
                配布されたQRコードよりログインしてください
            </div>
            <div class="d-flex justify-content-between mt-2" style="margin-bottom: 80px;">
                <a href="/school/" class="btn btn-light">学校の先生はこちら</a>
                <a href="/distributor/" class="btn btn-light">販売店様はこちら</a>
            </div>
            <div class="login">
                <div class="text-center mt-1">
                    <a href="https://technocraf.com/" class="btn btn-dark">
                        テクノクラフ サービス紹介サイト ▶︎
                    </a>
                </div>
                <p class="mt-3">
                    テクノクラフは学習指導要領に則ったタブレット教材を提供しています。教師も児童/生徒もいつでも簡単に使える、インストール不要のWEBアプリを開発し続けます。
                </p>
            </div>
        </div>
    </main>
</template>

<script>

export default {
    name: 'App',
}
</script>

<style>
html,body {width: 100%; height: 100%;}
.main {position: absolute; left: 0; right: 0; top: 100px; width: 440px; height: 330px; margin: auto; padding: 20px; background: #FFF; }
.login {position: absolute; left: 0; right: 0; width: 440px; height: 200px; margin: auto; padding: 20px; background: #FFF; }
.main img {display: block; width: 100%;}
.title {font-size: 1.5rem; text-align: center;}
</style>
